.p-paginator {
    padding: 0 !important;
}

.p-paginator .p-paginator-current {
    font-size: 13px;
    padding: 1rem;
}

.p-datatable .p-paginator-bottom {
    max-height: 3rem;
    border-width: 0 0 1px 0;
    border-radius: 0;
    align-items: center;
    justify-content: center;
}

.p-datatable .p-column-title {
    font-weight: bold;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    border: 0 none;
    min-width: 2rem;
    height: 2rem;
    margin: .143rem;
    transition: none;
    border-radius: 50%
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    border: 0 none;
    border-radius: 50%;
}

.p-datatable-emptymessage {
    font-size: 13px;
}

.p-datatable .p-sortable-column.p-highlight {
    color: rgba(0, 0, 0, 0.87);
    color: #3B82F6;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #3B82F6;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: #3B82F6;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #3B82F6;
}

.p-checkbox .p-checkbox-box {
    border-radius: 4px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: #3B82F6;
    border-color: #3B82F6;

}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: #3B82F635;
    color: rgba(0, 0, 0, 0.87);
}

.p-checkbox {
    display: flex;
    top: -1px;
}

.p-checkbox *,
.p-checkbox *::before,
.p-checkbox *::after {
    box-sizing: initial !important;
    max-height: 14px;
    max-width: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 4px;
    left: 0px;
    border-right: 0.19rem solid transparent;
    border-bottom: 0.19rem solid transparent;
}